import { IconDefinition, faUser, faEnvelope, faAsterisk } from '@fortawesome/free-solid-svg-icons'

// Semantic, shared definitions for reused icons.
// The alias names might make more sense and allow bulk update.

export const iconEmailField : IconDefinition = faEnvelope;
export const iconPasswordField : IconDefinition = faAsterisk;
export const iconUser : IconDefinition = faUser;


