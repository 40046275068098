
export enum StorytellerDomain {
  // Development
  Localhost,
  JungleHorse,
  // Production
  Storyteller,
  StorytellerStream,
}

//// Web-accessible, user-facing (non-API) domain names and subdomains
//export enum StorytellerSubdomain {
//  // Development
//  Localhost = "localhost",
//  JungleHorse = "jungle.horse",
//  JungleHorseObs = "obs.jungle.horse",
//  JungleHorseCreate = "jungle.horse",
//  // TODO: JungleHorseCreate = "create.jungle.horse",
//  // Storyteller
//  Storyteller = "storyteller.io",
//  StorytellerCreate = "create.storyteller.io",
//  StorytellerObs = "obs.storyteller.io",
//}
