export let wavesurferConfigs = [
  {
    filename: "/assets/preview/tracer.wav",
    title: "Tracer",
  },
  {
    filename: "/assets/preview/son-goku.wav",
    title: "Son Goku",
  },
  {
    filename: "/assets/preview/stan-lee.wav",
    title: "Stan Lee",
  },
  {
    filename: "/assets/preview/monokuma.wav",
    title: "Monokuma",
  },
  // {
  //   filename: "/assets/preview/monokuma.wav",
  //   title: "Monokuma",
  // },
  // {
  //   filename: "/assets/preview/monokuma.wav",
  //   title: "Monokuma",
  // },
  // {
  //   filename: "/assets/preview/monokuma.wav",
  //   title: "Monokuma",
  // },
  // {
  //   filename: "/assets/preview/monokuma.wav",
  //   title: "Monokuma",
  // },
  // {
  //   filename: "/assets/preview/monokuma.wav",
  //   title: "Monokuma",
  // },
  // {
  //   filename: "/assets/preview/monokuma.wav",
  //   title: "Monokuma",
  // },
  // {
  //   filename: "/assets/preview/monokuma.wav",
  //   title: "Monokuma",
  // },
  // {
  //   filename: "/assets/preview/monokuma.wav",
  //   title: "Monokuma",
  // },
];
